export const SunriseIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      height="50px"
      width="50px"
      version="1.1"
      id="Layer_1"
      viewBox="0 0 511.984 511.984"
    >
      <g>
        <path
          style={{ fill: "#F6BB42" }}
          d="M95.998,282.643H32c-5.891,0-10.664,4.781-10.664,10.688c0,5.875,4.773,10.656,10.664,10.656h63.998   c5.89,0,10.663-4.781,10.663-10.656C106.661,287.425,101.888,282.643,95.998,282.643z"
        />
        <path
          style={{ fill: "#F6BB42" }}
          d="M479.985,282.643h-63.997c-5.891,0-10.656,4.781-10.656,10.672s4.766,10.672,10.656,10.672h63.997   c5.891,0,10.656-4.781,10.656-10.656C490.641,287.425,485.876,282.643,479.985,282.643z"
        />
      </g>
      <path
        style={{ fill: "#FFCE54" }}
        d="M406.832,127.39l-45.25,45.257c-4.155,4.164-4.155,10.914,0,15.077  c4.156,4.172,10.922,4.172,15.094,0l45.249-45.248c4.156-4.164,4.156-10.922,0-15.086  C417.769,123.226,411.004,123.226,406.832,127.39z"
      />
      <path
        style={{ fill: "#F6BB42" }}
        d="M245.328,69.33v63.998c0,5.891,4.773,10.664,10.664,10.664c5.891,0,10.664-4.773,10.664-10.664V69.33  c0-5.89-4.773-10.671-10.664-10.671S245.328,63.44,245.328,69.33z"
      />
      <g>
        <path
          style={{ fill: "#FFCE54" }}
          d="M90.068,142.476l45.248,45.256c4.164,4.164,10.922,4.164,15.086,0   c4.164-4.163,4.164-10.921,0-15.085l-45.257-45.257c-4.164-4.164-10.913-4.164-15.077,0   C85.896,131.555,85.896,138.312,90.068,142.476z"
        />
        <path
          style={{ fill: "#FFCE54" }}
          d="M146.426,335.985c-5.305-13.562-7.984-27.89-7.984-42.654c0-64.826,52.732-117.559,117.551-117.559   c64.812,0,117.559,52.732,117.559,117.559c0,14.765-2.688,29.093-8,42.654H146.426z"
        />
      </g>
      <path
        style={{ fill: "#F6BB42" }}
        d="M255.992,165.1c-70.811,0-128.215,57.405-128.215,128.23c0,19.03,4.148,37.076,11.586,53.311h233.25  c7.438-16.234,11.594-34.28,11.594-53.311C384.207,222.506,326.803,165.1,255.992,165.1z M358.02,325.329H153.972  c-3.234-10.296-4.867-21.014-4.867-31.998c0-28.562,11.116-55.405,31.311-75.591c20.188-20.188,47.022-31.304,75.576-31.304  c28.562,0,55.39,11.116,75.576,31.304c20.202,20.186,31.311,47.029,31.311,75.591C362.879,304.315,361.238,315.033,358.02,325.329z"
      />
      <path
        style={{ fill: "#ED5564" }}
        d="M286.163,393.733l-22.632-22.624l0,0c-1.938-1.938-4.593-3.125-7.539-3.125l0,0  c-2.938,0-5.602,1.188-7.531,3.109l-0.008,0.016l-22.632,22.624c-4.164,4.156-4.164,10.906,0,15.094  c2.086,2.063,4.813,3.109,7.547,3.109c2.727,0,5.461-1.047,7.538-3.109l4.422-4.438v38.249c0,5.906,4.773,10.687,10.664,10.687l0,0  c5.891,0,10.664-4.78,10.664-10.687v-38.249l4.422,4.438c2.07,2.078,4.82,3.125,7.539,3.125c2.733,0,5.468-1.047,7.546-3.125  C290.335,404.655,290.335,397.889,286.163,393.733z"
      />
      <path
        style={{ fill: "#656D78" }}
        d="M501.328,325.329H10.664C4.773,325.329,0,330.095,0,335.985s4.773,10.656,10.664,10.656h490.664  c5.875,0,10.656-4.766,10.656-10.656S507.203,325.329,501.328,325.329z"
      />
    </svg>
  );
};
